import React, { useEffect, useState } from "react";
import "./sections1.css";
// import BackgroundImage from "../../../assets/background.png";

// import IndexxText from "../../../assets/indexx.ai white.png";
// import IndexxText from "../../../assets/indexx_group.svg";
import IndexxText from "../../../assets/indexx.ai_new.png";
import sportbets from "../../../assets/homepage/sports.svg";
import lotto from "../../../assets/homepage/loto.png";
import updatedLotto from "../../../assets/homepage/updated/lotto.png";
import market from "../../../assets/homepage/xmarket.svg";

// import wallet from "../../../assets/homepage/wallet.svg";
import wallet from "../../../assets/homepage/updated/wallet.png";

// import academy from "../../../assets/homepage/xacademy.png";
import academy from "../../../assets/homepage/xacademy.png";

// import aii from "../../../assets/homepage/aii.png";
import aii from "../../../assets/homepage/updated/aii.png";

// import nft from "../../../assets/homepage/nft.png";
import nft from "../../../assets/homepage/updated/nft.png";

import meme from "../../../assets/homepage/updated/meme.png";
// import powerpack from "../../../assets/homepage/powerpack.png";
import powerpack from "../../../assets/homepage/updated/powerpack.png";
// coming
// import marketlogo from "../../../assets/homepage/market.png";
import marketlogo from "../../../assets/homepage/updated/market.png";

// import xnft from "../../../assets/homepage/xnft.svg";
import xnft from "../../../assets/homepage/updated/xnft.png";

// import sbex from "../../../assets/homepage/sbex.png";
import sbex from "../../../assets/homepage/updated/sbex.png";

// import sorekt from "../../../assets/homepage/SoRekt.png";
import sorekt from "../../../assets/homepage/updated/SoRekt.png";

import ai from "../../../assets/homepage/ai engine.png";

//exchange logo
import exch from "../../../assets/homepage/exchange logo.png";
import updatedExch from "../../../assets/homepage/updated/exchange.png";

// import swap from "../../../assets/homepage/Swap logo.png";
import swap from "../../../assets/homepage/updated/swap.png";

import gc from "../../../assets/homepage/gc.svg";
import wis from "../../../assets/homepage/wis.svg";

// import y from "../../../assets/homepage/y.png";

// import shop from "../../../assets/homepage/shop.png";
import shop from "../../../assets/homepage/updated/shop.png";

// import token from "../../../assets/homepage/token.png";
import token from "../../../assets/homepage/updated/token.png";

// import hive from "../../../assets/homepage/Hive icon.png";
import hive from "../../../assets/homepage/updated/hive.png";

// import wallstreet from "../../../assets/homepage/Wall street icon.png";
import wallstreet from "../../../assets/homepage/updated/wallstreet.png";
import backToCommunityLogo from "../../../assets/homepage/updated/back to community 1.svg";
import wisLogo from "../../../assets/homepage/updated/WIBS.svg";
import daCrazyLogo from "../../../assets/homepage/daCrazyImg.svg";

// import { Link } from "react-router-dom";
import {
  baseCEXURL,
  baseDEXURL,
  baseAcademyUrl,
  decodeJWT,
  getUserShortToken,
  baseNewCEXURL,
} from "../../../services/api";
// import { Button } from "antd";
import { Tooltip, Image } from "antd";
import { Link } from "react-router-dom";

const Section1 = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    setIsAuthenticated(!!token);
  }, []);

  const isMobile = width <= 768;

  const handleNavigation = async (url: string) => {
    const isAuthenticated = localStorage.getItem("access_token");

    const email = localStorage.getItem("email");
    //debugger;
    let shortToken;
    if (email) {
      shortToken = await getUserShortToken(email);
    } else if (isAuthenticated) {
      let decodedValue: any = await decodeJWT(isAuthenticated);
      shortToken = await getUserShortToken(decodedValue?.email);
    }

    if (isAuthenticated) {
      const urlWithToken = `${url}?signInToken=${shortToken?.data}`;
      //debugger;
      window.location.href = urlWithToken;
    } else {
      window.location.href = url;
    }
  };

  return (
    <div className="home-containers" style={{ backgroundColor: "#181A20" }}>
      <div style={{ marginTop: "100px" }}></div>
      <div className="home-main-container">
        <div
          className="home-main-container-left"
          style={{
            flex: "60%",
          }}
        >
          <h1
            style={{
              textAlign: "left",
              width: "85%",
            }}
          >
            {" "}
            Welcome to Crypto Paradise.
          </h1>
        </div>
        {!isAuthenticated ? (
          <div className="home-main-container-right-new">
            <p>Sign In for the best experience</p>
            <div style={{ display: "flex", gap: 10 }}>
              <Link
                to="/auth/signup-email"
                style={{
                  color: "white",
                  marginTop: "10px",
                  flex: 1,
                  border: "1px solid #11BE6A",
                  padding: "10px 15px",
                  textAlign: "center",
                }}
                className="signOutHover"
              >
                Register
              </Link>
              <Link
                to="/auth/login"
                style={{
                  marginTop: "10px",
                  flex: 1,
                  background: "#11BE6A",
                  padding: "10px 15px",
                  textAlign: "center",
                  color: "black",
                }}
                className="signInHover"
              >
                Log in
              </Link>
            </div>
          </div>
        ) : (
          <></>
          // <div
          //   className="home-main-container-right"
          //   style={{
          //     display: "flex",
          //     justifyContent: "center",
          //     alignItems: "center",
          //     flexDirection: "column",
          //     flex: "30%",
          //   }}
          // >
          //   {" "}
          //   <p>Feature of the Month</p>
          //   <div
          //     onClick={() =>
          //       handleNavigation("https://whoisbitcoinsatoshi.wtf/")
          //     }
          //     style={{
          //       fontStyle: "italic",
          //       color: "white",
          //       marginTop: "10px",
          //       display: `${isMobile ? "block" : "flex"}`,
          //     }}
          //   >
          //     <span>
          //       <img src={wisLogo} height="90px" />
          //     </span>
          //   </div>
          // </div>
        )}
      </div>
      {/* <div style={{ display: isMobile ? "none" : "block" }}>
        <img
          src={IndexxText}
          alt="Indexx Logo"
          className="center-Image"
          style={{ width: "340px", height: "110px" }}
        />
      </div> */}
      {/* <div
        className="section-helper-text"
        style={{ marginTop: "-10px", display: isMobile ? "none" : "block" }}
      >
        Welcome to crypto paradise. Click each icon below and have fun!
      </div> */}
      {/* <div
        style={{
          display: isMobile ? "block" : "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "3px",
          marginTop: "30px",
        }}
      >
        <p
          style={{
            fontStyle: "italic",
            color: "white",
            transform: "translateY(-3px)",
          }}
        >
          Celebrate Bitcoin halving with
        </p>
        <Link
          to="https://whoisbitcoinsatoshi.wtf"
          style={{
            fontStyle: "italic",
            color: "white",
            marginTop: "10px",
            display: `${isMobile ? "block" : "flex"}`,
          }}
        >
          <span>
            <img src={wisLogo} height="60px" />
          </span>
        </Link>
      </div> */}

      <br />

      <div
        className="conatiner-fluid d-flex justify-content-center flex-column "
        style={{
          maxWidth: "1100px",
          width: "100%",
          padding: "20px",
          margin: "0px auto -30px auto",
        }}
      >
        <div className="home-main-container-right-feature">
          <div className="feature-of-month">
            <p>Feature of the Month</p>
            <div
              onClick={() =>
                handleNavigation("https://whoisbitcoinsatoshi.wtf/")
              }
              style={{
                fontStyle: "italic",
                color: "white",
                marginTop: "10px",
                display: `${isMobile ? "block" : "flex"}`,
              }}
            >
              <span>
                <img src={wisLogo} height="90px" />
              </span>
            </div>
          </div>{" "}
          <div
            className="daCrazyContainer"
            onClick={() => handleNavigation("https://dacrazyhawaiian.wtf/")}
          >
            <img src={daCrazyLogo} />
          </div>
        </div>

        {/* <div>
          {!isAuthenticated ? (
            <>
              <h1
                style={{
                  fontSize: 60,
                  fontWeight: 500,
                  color: "#FA8D01",
                  textAlign: "center",
                }}
              >
                Biggest deal of the season
              </h1>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <Link
                  to="https://whoisbitcoinsatoshi.wtf/coin"
                  style={{
                    marginTop: "10px",
                    flex: 1,
                    background: "#FA8D01",
                    padding: "10px 10px",
                    maxWidth: 250,
                    borderRadius: 5,
                    textAlign: "center",
                    color: "black",
                    marginBottom: "80px",
                  }}
                  className="wibsBuyHover"
                >
                  Buy Now
                </Link>
              </div>
            </>
          ) : (
            <></>
          )}
        </div> */}
        <div
          style={{
            display: isMobile ? "none" : "flex",
            justifyContent: "center",
            marginBottom: "30px",
            marginTop: "80px",
          }}
        >
          <img
            src={IndexxText}
            alt="Indexx Logo"
            className="center-Image"
            style={{ height: "50px" }}
          />
        </div>
        <div className={`${isMobile ? "d-block" : "d-flex"} mx-2 mt-4`}>
          <div className="abc">
            <div
              onClick={() => handleNavigation(baseNewCEXURL)}
              style={{ cursor: "pointer" }}
            >
              <img src={updatedExch} height="60px" />

              <span className="desc" style={{ color: "white" }}>
                {" "}
                Exchange / Buy Crypto
              </span>
              <span className="desc2" style={{ color: "white" }}>
                Crypto Exchange where you Buy/Sell and Convert Crypto Currencies
              </span>
            </div>
          </div>
          <div className="abc">
            <div
              onClick={() => handleNavigation("https://lotto.indexx.ai")}
              style={{ cursor: "pointer" }}
            >
              <img src={updatedLotto} height="60px" />

              <span className="desc" style={{ color: "white" }}>
                {" "}
                Fantasy Lotto
              </span>
              <span className="desc2" style={{ color: "white" }}>
                A Crypto based lottery where you can earn big rewards
              </span>
            </div>
          </div>
          {/* <div className="abc">
            <Link to="/meme-details">
              <img src={meme} height="60px" />

              <span className="desc" style={{ color: "white" }}>
                {" "}
                Meme Maniac
              </span>
              <span className="desc2" style={{ color: "white" }}>
                Meme based crypto
              </span>
            </Link>
          </div> */}
          {/* Academy here */}
          <div className="abc">
            <a href="https://shop.indexx.ai/">
              <img className="shopImage" src={shop} height="60px" />

              <span
                className="desc"
                style={{
                  color: "white",
                }}
              >
                {" "}
                Shop
              </span>
              <span className="desc2" style={{ color: "white" }}>
                Mail & E-Mail Gift Store
              </span>
            </a>
          </div>
          <div className="abc">
            <Link to="https://indexx.ai/indexx-exchange/token-details">
              <img src={token} height="60px" />

              <span className="desc" style={{ color: "white" }}>
                {" "}
                xTokens
              </span>
              <span className="desc2" style={{ color: "white" }}>
                Dive into all the tokens made by Indexx.ai
              </span>
            </Link>
          </div>
        </div>

        <div className={`${isMobile ? "d-block" : "d-flex"} mx-2 mt-4`}>
          <div className="abc">
            <div
              onClick={() => handleNavigation("https://hive.indexx.ai/")}
              style={{ cursor: "pointer" }}
            >
              <img src={hive} height="60px" />

              <span className="desc" style={{ color: "white" }}>
                {" "}
                Hive
              </span>
              <span className="desc2" style={{ color: "white" }}>
                Guidance system made for new crypto enthusiasts to learn and
                earn
              </span>
            </div>
          </div>
          <div className="abc">
            <div
              onClick={() => handleNavigation("https://academy.indexx.ai/")}
              style={{ cursor: "pointer" }}
            >
              <img src={academy} height="60px" />

              <span className="desc" style={{ color: "white" }}>
                {" "}
                Academy
              </span>
              <span className="desc2" style={{ color: "white" }}>
                A learning portal made for new crypto enthusiasts
              </span>
            </div>
          </div>

          <div className="abc">
            <Link to="https://sorekt.wtf/ai-engine-free-version">
              <img src={aii} height="60px" />

              <span className="desc" style={{ color: "white" }}>
                {" "}
                Ai Engine
              </span>
              <span className="desc2" style={{ color: "white" }}>
                Ai powered nft/image generation
              </span>
            </Link>
          </div>
          <div className="abc">
            <Link to="https://wallet.indexx.ai/">
              <img src={wallet} height="60px" />

              <span className="desc" style={{ color: "white" }}>
                {" "}
                Smart Wallet
              </span>
              <span className="desc2" style={{ color: "white" }}>
                A very safe wallet used to hold everything crypto
              </span>
            </Link>
          </div>
        </div>

        {/* <div
          className="section-helper-text"
          style={{ margin: !isMobile ? "30px auto" : "0px auto" }}
        >
          Exclusive for Elite Members
        </div> */}

        {/* <div className={`${isMobile ? "d-block" : "d-flex"} mx-2 mt-4`}>
          <div className="abc">
            <Link to={baseDEXURL}>
              <img src={swap} height="60px" />
              <span
                className="desc"
                style={{
                  color: "white",
                }}
              >
                {" "}
                Swap
              </span>
              <span className="desc2" style={{ color: "white" }}>
                A Decentralized crypto swap application to do anonymous
                transactions
              </span>
            </Link>
          </div>

          <div className="abc">
            <Link to="/indexx-exchange/coming-soon">
              <img src={sportbets} height="60px" />

              <span className="desc" style={{ color: "white" }}>
                {" "}
                Sport Bets
              </span>
            </Link>
          </div>
          <div className="abc">
            <Link to="/indexx-exchange/coming-soon">
              <img src={sbex} height="60px" />

              <span className="desc" style={{ color: "white" }}>
                {" "}
                SBEX
              </span>
            </Link>
          </div>
        </div> */}
        {/* <br />
        <br />
        <br /> */}
        {/* <div className="d-flex  mx-2 mt-4">
 
          <div className="abc">
            <a to="https://indexx.ai/indexx-exchange/coming-soon">
              <img src={sorekt} height="60px" />

              <span className="desc" style={{ color: "white" }}>
                {" "}
                SoRekt
              </span>
            </a>
          </div>
          <div className="abc"></div>
          <div className="abc"></div>
          <br />
          <br />
          <br />
          <br />
        </div> */}
      </div>
    </div>
  );
};

export default Section1;
