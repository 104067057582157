import React from "react";
import { makeStyles } from "@mui/styles";
import GenericButton from "../Button";
import passwordChanged from "../../../../assets/popup/loginPage.png";
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  dataShow: {
    opacity: "1 !important",
    visibility: "visible !important",
    "& .bnModalWrap": {
      transform: "scale(1) !important",
    },
  },
  bidsFullModal: {},
  bnMask: {
    alignItems: "center",
    backgroundColor: " rgba(0, 0, 0, .5)",
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    left: 0,
    position: "fixed",
    right: 0,
    top: 0,
    zIndex: 1200,
    width: "100%",
    height: "100vh",
  },
  bnTrans: {
    opacity: 0,
    transitionDuration: "250ms",
    transitionProperty: "all",
    transitionTimingFunction: "ease-in-out",
    visibility: "hidden",
  },
  bnModal: {
    "& .bnModalWrap": {
      backgroundColor: theme?.palette?.mode === "light" ? "#ffffff" : "#1e2329",
      borderRadius: "16px",
      boxShadow: "0px 3px 6px rgba(0,0,0,.04)",
      maxWidth: "80vw",
      overflow: "hidden",
      position: "relative",
      transform: "scale(.9)",
      transitionDuration: "250ms",
      transitionProperty: "all",
      transitionTimingFunction: "ease-in-out",
      width: "460px",
    },
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "32px",
    textAlign: "center",
    color: `${theme?.palette?.text?.primary} !important`,
    "& h3": {
      color: `${theme?.palette?.text?.primary} !important`,
      fontSize: "38px",
      fontWeight: 600,

      marginTop: "-10px",
    },
    "& h4": {
      color: `${theme?.palette?.text?.primary} !important`,
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "22px",
      margin: "10px 0px 15px 0px",
    },
    "& p": {
      color: `${theme?.palette?.text?.primary} !important`,
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "22px",
    },
  },
  btnContainer: {
    display: "flex",
    width: "100%",
    gap: "15px",
    marginTop: "25px",
  },
  cancelButton: {
    background:
      theme?.palette?.mode === "light"
        ? "#EAECEF !important"
        : "#2B3139 !important",
    color: `${theme?.palette?.text?.primary} !important`,
  },
  howItWorksButton: {
    background: "none !important",
    color: `#11BE6A !important`,
    border: `1px solid #11BE6A !important`,
  },
}));

const LoginPopup = ({
  onClose,
  onExploreIndexx,
  onBuyToken,
  redirectWebsiteLink,
}) => {
  const classes = useStyles();

  // Map URLs to their corresponding names
  const urlNameMap = {
    "shop.indexx.ai": "Shop",
    "cex.indexx.ai": "Exchange",
    "lotto.indexx.ai": "Fantasy Lotto",
    "academy.indexx.ai": "Academy",
  };

  const getRedirectName = (url) => {
    try {
      const hostname = new URL(url).hostname;
      return urlNameMap[hostname] || "the site"; // Default name in case the hostname doesn't match
    } catch (error) {
      console.error("Invalid URL:", url);
      return "the site";
    }
  };

  const redirectName = redirectWebsiteLink
    ? getRedirectName(redirectWebsiteLink)
    : "";

  return (
    <div
      className={`${classes.bnTrans} ${classes.dataShow} ${classes.bnMask} ${classes.bnModal}  ${classes.bidsFullModal}`}
    >
      <div className="bnModalWrap">
        <div className={classes.contentContainer}>
          {redirectWebsiteLink ? (
            <>
              <h4>Please wait while we redirect you to {redirectName}</h4>
              <CircularProgress sx={{ color: "#11BE6A" }} />
            </>
          ) : (
            <>
              <img src={passwordChanged} height="200px" />
              <h3>Congratulations!</h3>
              <p>You’ve just Logged In.</p>
              <div className={classes.btnContainer}>
                <GenericButton text="Buy Token" onClick={onBuyToken} />
                <GenericButton
                  text="Explore Indexx"
                  onClick={onExploreIndexx}
                  className={classes.howItWorksButton}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPopup;
