import React from "react";
// import { baseDEXURL,baseCEXURL } from "../../../services/api";
import "./TradeToEarnBanner.css";
import { baseCEXURL } from "../../../services/api";
import { Button, useMediaQuery } from "@mui/material";
// import banner from "../../../assets/SoRekt.png"
import bitcoinLogo from "../../../assets/banners/bitcoin_logo.svg";
import bitcoinText from "../../../assets/banners/bitcoin_text.svg";

import wibsLogo from "../../../assets/banners/wibs_logo.svg";
import wibsText from "../../../assets/banners/wibsTextUpdated.svg";

import shopText from "../../../assets/homeSlider/indexShopText.svg";
import shopImage from "../../../assets/homeSlider/indexShopImg.svg";

import newBannerText from "../../../assets/banners/new_updated_banner_textt.svg";
import newBannerGraphic from "../../../assets/banners/new_banner_graphic.png";

import btc_text from "../../../assets/banners/backToCommunityText.svg";
import btc_logo from "../../../assets/banners/backToCommunityImg.svg";

import inexLogo from "../../../assets/banners/inex_logo.svg";
import inexText from "../../../assets/banners/inex_text.svg";

import powerLogo from "../../../assets/banners/powerpack_logo.svg";
import powerText from "../../../assets/banners/powerpack_text.svg";
import { Link } from "react-router-dom";
import HomeSlider from "./BackToCommunitySlider";
import TestimonialSlider from "./TestimonialSlider";
import TestimonialBanner from "../../../assets/homeSlider/testimonialBanner.png";

const ClaimButton = ({ text, href }: { text: string; href: string }) => {
  return (
    <Link
      to={href}
      className="yellow_bg_btn"
      style={{ width: "283.047px", textAlign: "center" }}
    >
      {text}
    </Link>
  );
};
const TradeToEarnBanner = () => {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <>
      <div
        style={{
          background: "black",
          padding: "50px",
          margin: "50px auto 50px auto",
        }}
      >
        <div
          className="bitcoin_banner_root"
          style={{
            maxWidth: "1200px",
            width: "100%",
            margin: "auto",
            display: "flex",
            gap: "20px",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div
            className="bitcoinIcon"
            style={{
              display: "flex",
              alignItems: "center",
              flex: "30%",
            }}
          >
            <img src={shopImage} alt="bitcoinLogo" style={{ width: "90%" }} />
          </div>
          <div
            className="bitcoin_right"
            style={{
              flex: "70%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: "30px",
              }}
            >
              <img src={shopText} alt="text" style={{ width: "90%" }} />
            </div>
            <a
              href="https://shop.indexx.ai"
              className="blue_btn"
              style={{
                width: "283.047px",
                textAlign: "center",
                borderRadius: "10px",
              }}
            >
              Shop Now
            </a>
          </div>
        </div>
      </div>

      <div
        style={{
          background: "#FAFAFA",
          padding: "50px 50px 0px 50px",
          margin: "50px auto 50px auto",
        }}
      >
        <div
          style={{
            maxWidth: "1200px",
            width: "100%",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            className="new_banner_text"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img src={btc_text} style={{ width: "80%" }} />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "10px auto 70px auto",
            }}
          >
            <a
              href="/back-to-community"
              rel="noreferrer"
              className="register-links"
            >
              Learn more{" >"}
            </a>
          </div>
          <div className="new_banner_img">
            <img src={btc_logo} style={{ width: "100%" }} />
          </div>
        </div>
      </div>

      {/* New things here */}
      {/* <HomeSlider /> */}
      <div style={{ width: "100%", maxWidth: "1980px", margin: "0px auto" }}>
        <div
          className="testimonialbanner"
          style={{
            backgroundImage: `url(${TestimonialBanner})`,
          }}
        >
          <div
            style={{
              position: "absolute",
              bottom: 50,

              width: "100%",
              padding: "20px 60px",
            }}
          >
            <div className="testimonialtext" style={{ color: "white" }}>
              Testimonials
            </div>
            <div
              className="testimonialflex"
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <span className="testimonalsubText" style={{ color: "white" }}>
                Listen to what they say
              </span>
              <a
                href="https://www.youtube.com/watch?v=9ULdWShBz3k"
                className="white_btn"
                style={{
                  width: "283.047px",
                  textAlign: "center",
                  borderRadius: "10px",
                }}
              >
                Learn more
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <TestimonialSlider /> */}
      <div
        style={{
          background: "#FAFAFA",
          padding: "50px 50px 0px 50px",
          margin: "50px auto 50px auto",
        }}
      >
        <h2
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: !isMobile ? "72px" : "36px",
            marginBottom: 10,
            color: "black",
          }}
        >
          We hold a lot...
        </h2>
        <div
          style={{
            maxWidth: "1200px",
            width: "100%",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            className="new_banner_text"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img src={newBannerText} style={{ width: "100%" }} />
          </div>
          <div className="new_banner_img">
            <img src={newBannerGraphic} style={{ width: "100%" }} />
          </div>
        </div>
      </div>

      <div className="my-container d-flex flex-direction-column justify-content-center margin-lr-auto">
        <div>
          <div
            className="get_free_token"
            style={{
              position: "relative",
              marginTop: !isMobile ? "-70px" : "50px",
              marginBottom: !isMobile ? "-50px" : 0,
            }}
          >
            <div
              style={{
                marginTop: !isMobile ? "0px" : "80px",
                position: "absolute",
                width: "50%",
                left: 0,
                bottom: !isMobile ? 50 : 5,
              }}
            >
              <div>
                <a
                  href="https://lotto.indexx.ai/reward"
                  target="_blank"
                  rel="noreferrer"
                  className="register-links"
                >
                  Claim Now{" >"}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div
          className="hive_banner"
          style={!isMobile ? { marginTop: "100px" } : { marginTop: "50px" }}
        >
          <div>
            <a href="https://hive.indexx.ai/" className="hive-link">
              Learn More{" >"}
            </a>
          </div>

          <div>
            <a href="https://hive.indexx.ai/sign-up" className="hive-link">
              Join Indexx Hive{" >"}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default TradeToEarnBanner;
