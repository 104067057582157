import { Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "./HowItWorks.css";

// exchange
import exchn_dark from "../../assets/homepage/updated/exchange.png";
import exchn from "../../assets/how-it-works/exchange-light-mode.svg";

// Lotto
import updatedLotto from "../../assets/homepage/updated/lotto.png";

// Academy
import academy from "../../assets/homepage/xacademy.png";

// Tokens
import token from "../../assets/homepage/updated/token.png";
// Memeniac
import meme from "../../assets/homepage/updated/meme.png";
// wallstreet
import wallstreet from "../../assets/homepage/updated/wallstreet.png";

import hive from "../../assets/how-it-works/hive.png";
import ai_engine from "../../assets/how-it-works/ai engine.png";
import smart_wallet from "../../assets/how-it-works/smartWallet.png";
import shopLightMode from "../../assets/how-it-works/shopLightMode.svg";
import shopDarkMode from "../../assets/how-it-works/ShopDarkMode.svg";

import step1 from "../../assets/how-it-works/1.svg";
import step2Dark from "../../assets/how-it-works/step2 dark.svg";
import step2Light from "../../assets/how-it-works/step2 light.svg";
import step3Light from "../../assets/how-it-works/2.svg";
import step3Dark from "../../assets/how-it-works/2.svg";

import { Button } from "antd";

const { Text } = Typography;

interface Item {
  label: string;
  imgSrc: string;
}

interface SectionProps {
  header: string;
  text: string;
  text2?: string;
  lightImg: string;
  darkImg: string;
  theme: string;
}

const HowWorks: React.FC = () => {
  const navigate = useNavigate();
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [theme, setTheme] = useState<string>(
    localStorage.getItem("selectedTheme") || "dark"
  );
  const handleSubmit = () => {};

  const navigateUser = (path: string) => {
    window.localStorage.setItem("redirect", window.location.pathname);
    navigate(path);
  };

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    const handleStorageChange = (event: any) =>
      setTheme(event.currentTarget.localStorage.selectedTheme);

    window.addEventListener("resize", handleResize);
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  const isMobile: boolean = width <= 768;

  // Define an array of objects for each item
  const items: Item[] = [
    {
      label: "Exchange / Buy Crypto",
      imgSrc: theme === "dark" ? exchn_dark : exchn,
    },
    {
      label: "Fantasy Lotto",
      imgSrc: theme === "dark" ? updatedLotto : updatedLotto,
    },
    {
      label: "Shop",
      imgSrc: theme === "dark" ? shopDarkMode : shopLightMode,
    },
    {
      label: "xTokens",
      imgSrc: theme === "dark" ? token : token,
    },
    {
      label: "Hive",
      imgSrc: hive,
    },
    {
      label: "Academy",
      imgSrc: academy,
    },
    {
      label: "Ai Engine",
      imgSrc: ai_engine,
    },
    {
      label: "Smart Wallet",
      imgSrc: smart_wallet,
    },
  ];
  const imgStyle = {
    marginBottom: "10px", // Adjust margin based on mobile or not
    marginTop: "50px", // Adjust margin based on mobile or not
  };

  const Section: React.FC<SectionProps> = ({
    header,
    text,
    text2,
    lightImg,
    darkImg,
    theme,
  }) => (
    <div
      style={{
        marginBottom: "50px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        maxWidth: "1000px",
        width: "100%",
      }}
    >
      <Text
        style={{
          fontSize: "62px",
          color: theme === "dark" ? "#EAECEF" : "#000000",
          textAlign: "center",
        }}
      >
        {header}
      </Text>
      <Text
        style={{
          marginTop: "10px",
          textAlign: "center",
          color: theme === "dark" ? "#EAECEF" : "#000000",
          fontSize: "30px",
          marginBottom: "20px",
        }}
      >
        {text}
      </Text>
      {header === "3" && (
        <Text
          style={{
            marginTop: "10px",
            textAlign: "center",
            color: theme === "dark" ? "#EAECEF" : "#000000",
            fontSize: "22px",
            marginBottom: "20px",
          }}
        >
          {text2}
        </Text>
      )}
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={theme === "dark" ? darkImg : lightImg}
          alt={header}
          style={{ width: "100%" }}
        />
      </div>
      {header === "1" && (
        <div className="button-container">
          <button
            className="atn-btn atn-btn-round"
            onClick={() => (window.location.href = "/auth/signup-email")}
          >
            Register
          </button>
          <button
            className="atn-btn atn-btn-round"
            onClick={() => (window.location.href = "/auth/login")}
          >
            Login
          </button>
        </div>
      )}
    </div>
  );

  // Define subtitle styles based on theme
  const subtitleStyle = {
    fontSize: `${isMobile ? "20px" : "36px"}`,
    marginTop: `${isMobile ? "20px" : "10px"}`,
    color: theme === "dark" ? "#EAECEF" : "#000000",
  };

  return (
    <div className="scan-container how-it-works flex-direction-column">
      <Text
        style={{
          marginBottom: `${isMobile ? "0px" : "20px"}`,
          fontSize: `${isMobile ? "45px" : "70px"}`,
          lineHeight: "1em",
          marginTop: `${isMobile ? "100px !important" : "250px"}`,
          fontWeight: "500",
          display: "block",
          color: theme === "dark" ? "#EAECEF" : "#000000",
        }}
      >
        How it Works
      </Text>

      <Text
        style={{
          marginBottom: `${isMobile ? "0px" : "100px"}`,
          fontSize: `${isMobile ? "24px" : "24px"}`,
          lineHeight: "1em",
          marginTop: `${isMobile ? "-20px" : "10px"}`,
          color: theme === "dark" ? "#EAECEF" : "#000000",
          textAlign: "center",
        }}
      >
        To gain an understanding of how indexx.ai operates, please refer to the
        following steps.
      </Text>
      <div className="row" style={{ maxWidth: "1200px", width: "100%" }}>
        {items.map((item, index) => (
          <div key={index} className="col-lg-3 text-center mb-5">
            <a target="_blank" rel="noopener noreferrer">
              <img
                src={item.imgSrc}
                alt="indexxcoin"
                height={"60px"}
                style={imgStyle}
              />
            </a>
            <br />
            <span className="employee_name">{item.label}</span>
          </div>
        ))}
      </div>
      <div style={{ display: "flex", flexDirection: "column", marginTop: 50 }}>
        <Text
          style={{
            margin: "auto",
            marginBottom: `${isMobile ? "0px" : "30px"}`,
            fontSize: `${isMobile ? "45px" : "70px"}`,
            lineHeight: "1em",
            marginTop: `${isMobile ? "50px" : "200px"}`,
            fontWeight: "500",
            color: theme === "dark" ? "#EAECEF" : "#000000",
          }}
        >
          Indexx Login & Signup
        </Text>

        {/* <Text style={subtitleStyle} className="subtitle">
          **The Connected Ecosystem**
        </Text> */}

        {/* Add your additional line of text here */}
        <Text
          className="subtext"
          style={{ color: theme === "dark" ? "#EAECEF" : "#000000" }}
        >
          With Indexx.ai, you only need to sign up or log in once to access our
          entire ecosystem.
        </Text>
      </div>
      <div>
        <div
          style={{ display: "flex", flexDirection: "column", marginTop: 50 }}
        >
          <Text
            style={{
              margin: "auto",
              marginBottom: `${isMobile ? "0px" : "80px"}`,
              fontSize: `${isMobile ? "45px" : "70px"}`,
              lineHeight: "1em",
              marginTop: `${isMobile ? "50px" : "250px"}`,
              fontWeight: "500",
              color: theme === "dark" ? "#EAECEF" : "#000000",
            }}
          >
            3 Easy Steps
          </Text>

          <Section
            header="1"
            text="Visit our signup/login page. Click on any of the options to continue"
            lightImg={step1}
            darkImg={step1}
            theme={theme}
          />
          <Section
            header="2"
            text="Fill in your details to create an account or log in if you already have one."
            lightImg={step2Light}
            darkImg={step2Dark}
            theme={theme}
          />
          <Section
            header="3"
            text="Enjoy one-click access to all our platforms."
            lightImg={step3Light}
            darkImg={step3Dark}
            theme={theme}
            text2="Green Oval Indicates you are logged in as a user, Indexxer"
          />
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default HowWorks;
