import React, { useContext, useEffect, useRef, useState } from "react";
// import greetingCard from "../../assets/header-icons/sec_header_greeting.svg";
import logo from "../../assets/header-icons/new indexx.ai logo.svg";
import "./style.css";
import CrossIcon from "../../assets/header-icons/cross";
import CrossIconNew from "../../assets/homepage/updated/cross_white.png";
import DarkMode from "../DarkMode/DarkMode";
import { Theme } from "../../utils/themeContext";
import frame from "../../assets/updated/header/captain.png";
import beeframe from "../../assets/updated/header/normal.png";
import { useLocation, useNavigate } from "react-router-dom";
import dummy from "../../assets/updated/dummy.png";
// import Fantasy_Lotto from '../../assets/BSheader/fantasy.png';
// import token from '../../assets/BSheader/tokens icon 1.svg';
// import token_white from '../../assets/BSheader/tokens icon  white (1).svg';
import Data, { auth_header_data } from "./data";
import { useMediaQuery } from "@mui/material";
import {
  getCaptainBeeStatics,
  getHoneyUserDetails,
  getCaptainBeeByEmail,
  checkByemail,
} from "../../services/api";
const HeaderTest: React.FC = () => {
  const { pathname } = useLocation();
  const themeData = useContext(Theme);
  const timeoutRef = useRef<any>(null);
  const elementRef = useRef(null);
  const [headerData, setHeaderData] = useState(Data);
  const [backdropVisibility, setBackdropVisibility] = useState(false);
  const [theme, setTheme] = useState<any>(themeData?.theme ?? "dark");
  const [activeIndex, setactiveIndex] = useState(0);
  const isMobile = useMediaQuery("(max-width:768px)");
  const isAuthenticated = localStorage.getItem("access_token") !== null;

  const [isCaptain, setisCaptain] = useState(false);
  const [loading, setLoading] = useState(true);

  const [userProfile, setUserProfile] = useState();
  const [profileLoading, setProfileLoading] = useState(true);
  const userEmail =
    localStorage.getItem("user") || localStorage.getItem("email") || "";

  const navigate = useNavigate();
  useEffect(() => {
    if (themeData?.theme) {
      setTheme(themeData?.theme);
    }
  }, [themeData?.theme]);

  useEffect(() => {
    if (pathname === "/discount-shop")
      setHeaderData(
        headerData.map((el) => {
          if (el.mainTextDesktop === "Shop")
            return {
              ...el,
              active: true,
            };
          return el;
        })
      );
  }, [pathname]);
  const handleItemClick = (path: string, i: number) => {
    setactiveIndex(i);
    window.location.href = path;
  };
  const handleMouseEnter = () => {
    // Set a timeout to open the dropdown after 0.2s
    timeoutRef.current = setTimeout(() => {
      setBackdropVisibility(true);
    }, 200);
  };

  const handleMouseLeave = () => {
    // Clear the timeout if user leaves before 0.2s
    clearTimeout(timeoutRef.current);
    // Hide the dropdown after a delay
    setTimeout(() => {
      setBackdropVisibility(false);
    }, 200);
  };
  const updateBackDropVisibility = (type: string) => {
    if (type === "enter") handleMouseEnter();
    if (type === "leave") handleMouseLeave();
  };

  useEffect(() => {
    const element = elementRef.current;

    if (element) {
      const computedStyle = window.getComputedStyle(element);
      const visibility = computedStyle.getPropertyValue("visibility");
      const display = computedStyle.getPropertyValue("display");

      if (visibility === "hidden" || display === "none") {
        console.log("Element is hidden or not visible");
      } else {
        console.log("Element is visible");
        setBackdropVisibility(false);
      }
    }
  }, []);

  useEffect(() => {
    async function checkUserType() {
      let userType =
        localStorage.getItem("userType") !== undefined
          ? String(localStorage.getItem("userType"))
          : undefined;
      let username =
        localStorage.getItem("username") !== undefined
          ? String(localStorage.getItem("username"))
          : undefined;

      const user =
        localStorage.getItem("user") !== undefined
          ? String(localStorage.getItem("user"))
          : undefined;
      let getUserType = await checkByemail(String(user));
      userType = getUserType.userType;
      const accessToken =
        localStorage.getItem("access_token") !== undefined
          ? String(localStorage.getItem("access_token"))
          : undefined;
      // debugger;
      if (userType === "CaptainBee") {
        if (accessToken && accessToken !== "null" && accessToken !== "") {
          //debugger;
          let resObj = await getCaptainBeeByEmail(String(user));
          console.log("resObj", resObj);
          username = resObj?.data.Username;
        }
        setisCaptain(true);
        setLoading(false);
        if (username) {
          getCaptainBeeStatics(String(username)).then((data: any) => {
            setUserProfile(data?.data?.affiliateUserProfile?.photoIdFileurl);
            setProfileLoading(false);
          });
        }
      } else {
        setisCaptain(false);
        setLoading(false);
        getHoneyUserDetails(String(user)).then((data: any) => {
          setUserProfile(data?.data?._doc?.profilePic);
          setProfileLoading(false);
        });
      }
    }
    checkUserType();
  }, []);

  const logOutUser = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const userType =
      localStorage.getItem("userType") !== undefined
        ? String(localStorage.getItem("userType"))
        : undefined;
    localStorage.removeItem("user"); //remove one item
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("email");
    localStorage.clear(); //clear all localstorage
    console.log(userType);

    if (userType === "CaptainBee") {
      window.location.href = "/auth/login";
    } else if (userType === "HoneyBee") {
      window.location.href = "/auth/login";
    } else {
      if (window.location.pathname.includes("trade-to-earn"))
        window.location.reload();
      else window.location.href = "/auth/login";
    }
  };

  const handleLogout = (e: any, nm: string) => {
    if (nm !== "logout") return;
    logOutUser(e);
  };

  return (
    <>
      <nav style={{ position: "fixed", top: 0, left: 0, zIndex: 10000 }}>
        <div className="wrapper">
          {isMobile ? (
            ""
          ) : (
            <div
              className="backdrop"
              style={{
                display: "block",
                opacity: backdropVisibility ? 1 : 0,
                background: theme === "dark" ? "rgba(0,0,0,0.5)" : "",
                transitionDelay: ".1s",
                height: backdropVisibility ? "100vh" : 0,
              }}
            ></div>
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",

              flex: 1,
            }}
          >
            <div className="logo" style={{ marginRight: "30px" }}>
              <a href="/">
                <img src={logo} />
              </a>
            </div>
            <input type="radio" name="slider" id="menu-btn" />
            <input type="radio" name="slider" id="close-btn" />
            <ul
              className="nav-links"
              style={{
                flex: 1,
              }}
            >
              <label htmlFor="close-btn" className="btn close-btn">
                <img src={CrossIconNew} />
              </label>
              {isMobile &&
                auth_header_data
                  .filter((el) => el.isAuth === isAuthenticated)
                  .map((element, i) => (
                    <>
                      {element.mainTextDesktop === "Logout" ||
                      element.mainTextMob === "Logout" ? (
                        <li
                          className="main"
                          style={{
                            marginLeft: i === 0 ? "auto" : "",
                            display: "flex",
                            cursor: "pointer",
                          }}
                          // onClick={(e) =>
                          //   handleLogout(
                          //     e,
                          //     element.mainTextDesktop.toLocaleLowerCase()
                          //   )
                          // }
                        >
                          <a onClick={(e) => handleLogout(e, "logout")}>
                            Logout
                          </a>
                        </li>
                      ) : (
                        <li
                          className="main"
                          style={{
                            marginLeft: i === 0 ? "auto" : "",
                            display: "flex",
                          }}
                          // onMouseEnter={
                          //   isAuthenticated
                          //     ? () => updateBackDropVisibility("enter")
                          //     : () => updateBackDropVisibility("leave")
                          // }
                          // onMouseLeave={() => updateBackDropVisibility("leave")}
                        >
                          {isAuthenticated && (
                            <div
                              style={{
                                marginBottom: "-83px",
                                zIndex: "10000000",

                                transform: "translateY(10px)",
                              }}
                            >
                              <div
                                style={{
                                  width: isCaptain ? "60px" : "50px",
                                  height: isCaptain ? "80px" : "60px",
                                  backgroundImage: `url(${
                                    isCaptain ? frame : beeframe
                                  })`,
                                  transform: !isCaptain ? "rotate(-30deg)" : "",
                                  // backgroundImage: `url(${frame})`,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "center",
                                  position: "relative",
                                  cursor: "pointer",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  alignSelf: "center",
                                  // border:"none"
                                }}
                              >
                                <div
                                  className={
                                    isCaptain ? "bee-hexagon" : "elipse-img"
                                  }
                                  style={{
                                    marginBottom: `${
                                      isCaptain === true ? 0 : "7px"
                                    }`,
                                  }}
                                >
                                  <img
                                    alt=""
                                    src={userProfile ? userProfile : dummy}
                                    width={"63px"}
                                    height={"66px"}
                                    style={{
                                      border: "none",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          <a
                            href={element.href}
                            className={`desktop-item ${
                              element.active ? "link_active" : ""
                            }`}
                          >
                            {isAuthenticated
                              ? userEmail
                              : element.mainTextDesktop}
                          </a>
                          <input type="checkbox" id={element.mainTextDesktop} />

                          <a href={element.href} className="mobile-item">
                            {isAuthenticated
                              ? userEmail
                              : element.mainTextDesktop}
                          </a>

                          {element.hasMegaDrop ? (
                            <div
                              className="mega-box"
                              style={{
                                background:
                                  theme?.palette?.mode === "light"
                                    ? "#FAFAFC"
                                    : "",
                                color:
                                  theme?.palette?.mode === "light"
                                    ? "#333336 !important"
                                    : "",
                              }}
                              ref={elementRef}
                            >
                              <div className="content">
                                {element.dropDownContent.map((elem: any) => (
                                  <div
                                    className="row"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <header>{elem?.heading}</header>
                                    <ul
                                      className={`mega-links ${
                                        elem?.mainList ? "main" : ""
                                      }`}
                                    >
                                      {elem?.links.map((el: any) => (
                                        <li>
                                          <a
                                            onClick={(e) =>
                                              handleLogout(
                                                e,
                                                el.name.toLocaleLowerCase()
                                              )
                                            }
                                            href={el.href}
                                            className={
                                              theme?.palette?.mode === "light"
                                                ? "dark_color"
                                                : ""
                                            }
                                          >
                                            {el.name}
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                ))}

                                <div className="row"></div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </li>
                      )}
                    </>
                  ))}
              {headerData.map((element) => (
                <>
                  <li
                    className="main"
                    onMouseEnter={() => updateBackDropVisibility("enter")}
                    onMouseLeave={() => updateBackDropVisibility("leave")}
                  >
                    <a
                      href={element.href}
                      className={`desktop-item ${
                        element.active ? "link_active" : ""
                      }`}
                      onMouseEnter={() => updateBackDropVisibility("enter")}
                    >
                      {element.mainTextDesktop}
                    </a>
                    <input type="checkbox" id={element.mainTextDesktop} />
                    <label
                      htmlFor={element.mainTextDesktop}
                      className="mobile-item"
                    >
                      {element.mainTextMob} {element.hasMegaDrop ? ">" : ""}
                    </label>

                    {element.hasMegaDrop ? (
                      <div
                        className="mega-box"
                        ref={elementRef}
                        style={{
                          background: theme === "light" ? "#FAFAFC" : "",
                          color: theme === "light" ? "#333336 !important" : "",
                        }}
                      >
                        <div className="content">
                          {element.dropDownContent.map((elem) => (
                            <div
                              className="row"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <header
                                style={{
                                  color:
                                    theme === "light"
                                      ? "#A0A0A5 !important"
                                      : "",
                                }}
                              >
                                {elem.heading}
                              </header>
                              <ul
                                className={`mega-links ${
                                  elem.mainList ? "main" : ""
                                }`}
                              >
                                {elem.links.map((el) => (
                                  <li
                                    className="profile-list-item"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      textAlign: "left",
                                    }}
                                  >
                                    <a
                                      href={el.href}
                                      className={
                                        theme === "light" ? "dark_color" : ""
                                      }
                                    >
                                      {el.name}
                                    </a>
                                    <div
                                      className={`profile-inner-item-border-bottom `}
                                      style={{
                                        background: "#11BE6A",
                                        width: "16px",
                                        height: "3px",
                                        marginTop: "-5px",
                                      }}
                                    ></div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ))}

                          <div className="row"></div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </li>
                </>
              ))}

              {!isMobile &&
                auth_header_data
                  .filter((el) => el.isAuth === isAuthenticated)
                  .map((element, i) => (
                    <>
                      {element.mainTextDesktop === "Logout" ||
                      element.mainTextMob === "Logout" ? (
                        <li
                          className="main"
                          style={{
                            marginLeft: i === 0 ? "auto" : "",
                            display: "flex",
                            cursor: "pointer",
                            color: "white",
                          }}

                          // onClick={(e) =>
                          //   handleLogout(
                          //     e,
                          //     element.mainTextDesktop.toLocaleLowerCase()
                          //   )
                          // }
                        >
                          <a onClick={(e) => handleLogout(e, "logout")}>
                            Logout
                          </a>
                        </li>
                      ) : (
                        <li
                          className="main"
                          style={{
                            marginLeft: i === 0 ? "auto" : "",
                            display: "flex",
                          }}
                          // onMouseEnter={
                          //   isAuthenticated
                          //     ? () => updateBackDropVisibility("enter")
                          //     : () => updateBackDropVisibility("leave")
                          // }
                          // onMouseLeave={() => updateBackDropVisibility("leave")}
                        >
                          {isAuthenticated && !loading && (
                            <div
                              style={{
                                marginBottom: "-83px",
                                zIndex: "10000000",

                                transform: "translateY(10px)",
                              }}
                            >
                              <div
                                style={{
                                  width: isCaptain ? "60px" : "65px",
                                  height: isCaptain ? "80px" : "68px",
                                  backgroundImage: `url(${
                                    isCaptain ? frame : beeframe
                                  })`,
                                  transform: !isCaptain ? "rotate(-30deg)" : "",
                                  // backgroundImage: `url(${frame})`,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "center",
                                  position: "relative",
                                  cursor: "pointer",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  alignSelf: "center",
                                  // border:"none"
                                }}
                              >
                                {!profileLoading && (
                                  <div
                                    className={
                                      isCaptain ? "bee-hexagon" : "elipse-img"
                                    }
                                    style={{
                                      marginBottom: `${
                                        isCaptain === true ? 0 : "7px"
                                      }`,
                                    }}
                                  >
                                    <img
                                      alt=""
                                      src={userProfile ? userProfile : dummy}
                                      width={"63px"}
                                      height={"66px"}
                                      style={{
                                        border: "none",
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          <a
                            href={element.href}
                            className={`desktop-item ${
                              element.active ? "link_active" : ""
                            }`}
                          >
                            {isAuthenticated
                              ? userEmail
                              : element.mainTextDesktop}
                          </a>
                          <input type="checkbox" id={element.mainTextDesktop} />

                          <a href={element.href} className="mobile-item">
                            {isAuthenticated
                              ? userEmail
                              : element.mainTextDesktop}
                          </a>

                          {element.hasMegaDrop ? (
                            <div
                              className="mega-box"
                              style={{
                                background:
                                  theme?.palette?.mode === "light"
                                    ? "#FAFAFC"
                                    : "",
                                color:
                                  theme?.palette?.mode === "light"
                                    ? "#333336 !important"
                                    : "",
                              }}
                              ref={elementRef}
                            >
                              <div className="content">
                                {element.dropDownContent.map((elem: any) => (
                                  <div
                                    className="row"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <header>{elem?.heading}</header>
                                    <ul
                                      className={`mega-links ${
                                        elem?.mainList ? "main" : ""
                                      }`}
                                    >
                                      {elem?.links.map((el: any) => (
                                        <li>
                                          <a
                                            onClick={(e) =>
                                              handleLogout(
                                                e,
                                                el.name.toLocaleLowerCase()
                                              )
                                            }
                                            href={el.href}
                                            className={
                                              theme?.palette?.mode === "light"
                                                ? "dark_color"
                                                : ""
                                            }
                                          >
                                            {el.name}
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                ))}

                                <div className="row"></div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </li>
                      )}
                    </>
                  ))}
            </ul>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <DarkMode />
            <label htmlFor="menu-btn" className="btn menu-btn">
              <CrossIcon />
            </label>
          </div>
        </div>
      </nav>
      {headerData.find(
        (el) => el.active === true && el.hasMegaDrop === true
      ) ? (
        <div
          className="secondary_header_root"
          style={{ background: theme === "dark" ? "#000" : "" }}
        >
          <div className="secondary_header_content">
            {headerData
              .find((el) => el.active === true)
              ?.dropDownContent.find((elem) => elem.mainList === true)
              ?.links?.map((curr: any, i) => (
                <div
                  key={i}
                  className={`secondary_header_content_item`}
                  style={{ position: "relative" }}
                  onClick={() => handleItemClick(curr.href, i)}
                >
                  <span className="secondary_header_content_img_container">
                    <img
                      src={theme === "light" ? curr?.imgLight : curr?.imgDark}
                    />
                  </span>
                  <span>{curr.name}</span>
                  {i === activeIndex ? (
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        left: "50%",
                        transform: "translateX(-50%)",
                        width: "16px",
                        height: "2px",
                        background: "grey",
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                </div>
              ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default HeaderTest;
